<template>
  <div>
    <list
      ref="refPreviousList"
      sort-by="previoId"
      key-field="PrevioId"
      refetch-records-name="previos"
      report-name="Previos detallado.xlsx"
      template-name="Previos Acumulados.xlsx"
      :fetch-template="fetchPreviousAccumulatedExcel"
      :delete-method="deletePrevious"
      controller-name="Previos"
      :sort-dir-desc="false"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
    >
      <!-- Format date -->
      <template #cell(fechaEntrada)="data">
        <b-media v-if="stateTime.value" vertical-align="center">
          {{ data.item.fechaEntrada | formatDateTime }}
        </b-media>
        <b-media v-else vertical-align="center">
          {{ data.item.fechaEntrada | formatDate }}
        </b-media>
      </template>
      <template #cell(fechaPrevio)="data">
        <b-media v-if="stateTime.value" vertical-align="center">
          {{ data.item.fechaPrevio | formatDateTime }}
        </b-media>
        <b-media v-else vertical-align="center">
          {{ data.item.fechaPrevio | formatDate }}
        </b-media>
      </template>
      <template #cell(fechaHoraFinalizacionPrevio)="data">
        <b-media v-if="stateTime.value" vertical-align="center">
          {{ data.item.fechaHoraFinalizacionPrevio | formatDateTime }}
        </b-media>
        <b-media v-else vertical-align="center">
          {{ data.item.fechaHoraFinalizacionPrevio | formatDate }}
        </b-media>
      </template>
      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge
          v-if="data.item.previoEstado"
          pill
          :variant="`light-${resolvePreviousStatusVariant(data.item.previoEstado)}`"
          class="text-capitalize"
        >
          {{ data.item.previoEstado.nombre }}
        </b-badge>
      </template>
      <!-- Column: Detalle guia -->
      <template #cell(detalleGuia)="data">
        <span
          v-if="data.item.previoDetalleGuia"
          :class="resolvePreviousDetailsVariant(data.item.previoDetalleGuia)"
        >
          <strong>{{ data.item.previoDetalleGuia.nombre }}</strong>
        </span>
      </template>
      <!--Discrepancia -->
      <template #cell(discrepancia)="data">
        <b-badge
          pill
          :variant="`light-${resolvePreviousDisagreementVariant(data.item.discrepanciaCantidades)}`"
          class="text-capitalize"
        >
          {{ data.item.discrepanciaCantidades ? $t('Lists.Yes'): $t('Lists.No') }}
        </b-badge>
      </template>
    </list>
  </div>
</template>

<script>
import {
  BBadge,
  BMedia,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import common from '@/libs/common'
import List from '@/components/List.vue'
import previousService from '@/services/previous.service'

export default {
  components: {
    BBadge,
    BMedia,
    List,
  },
  setup() {
    const {
      fetchPreviousAccumulatedExcel,
      deletePrevious,
    } = previousService()
    /* Refs */
    const refPreviousList = ref(null)
    const dateTimeFormater = JSON.parse(localStorage.getItem('stateTime'))
    if (!dateTimeFormater.find(e => e.key === 'formatDatePrevius')) {
      dateTimeFormater.push({ key: 'formatDatePrevius', value: false })
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
    }
    const stateTime = ref(dateTimeFormater.find(e => e.key === 'formatDatePrevius'))

    let actions = ref([])
    const changeStateTime = () => {
      stateTime.value.value = !stateTime.value.value
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
      const index = actions.value.findIndex(e => e.name === 'dateAndHour')
      actions.value[index].value = stateTime.value.value
    }

    const { getUser } = common()

    /* Configuration */
    actions = ref([
      {
        label: i18n.t('AppPrevious.list.newPreviousButton'),
        aclAction: 'create',
        aclResource: 'Previos',
        routeName: 'apps-previous-add',
        params: { previoId: 0 },
        icon: 'PlusCircleIcon',
      },
      {
        name: 'downloadReport',
        aclAction: 'read',
        aclResource: 'Previos',
      },
      {
        label: i18n.t('AppPrevious.list.downloadTemplate'),
        name: 'downloadTemplate',
        aclAction: 'create',
        aclResource: 'Clasificaciones',
      },
      {
        name: 'uploadTemplate',
        aclAction: 'create',
        aclResource: 'Previos',
      },
      {
        name: 'dateAndHour',
        aclAction: 'read',
        aclResource: 'AccionesMejora',
        icon: 'CalendarIcon',
        value: stateTime.value.value,
        click: changeStateTime,
      },
    ])

    const filters = ref([
      {
        type: 'date',
        name: 'fechaDesde',
        label: i18n.t('AppPrevious.list.dateFrom'),
        value: '',
      },
      {
        type: 'date',
        name: 'fechaHasta',
        label: i18n.t('AppPrevious.list.dateTo'),
        value: '',
      },
      {
        type: 'select',
        name: 'aduana',
        label: i18n.t('AppPrevious.list.Customs'),
        value: '',
        options: [],
        domainName: 'Aduanas',
      },
      {
        type: 'select',
        name: 'clienteId',
        label: i18n.t('AppPrevious.list.Customer'),
        value: '',
        options: [],
        domainName: 'Clientes',
      },
    ])

    let tableColumns = [
      {
        label: i18n.t('AppPrevious.columns.previoID'),
        key: 'previoId',
        sortable: true,
        sortKey: 'PrevioId',
      },
      {
        label: i18n.t('AppPrevious.columns.details'),
        key: 'detalleGuia',
        sortable: true,
        sortKey: 'PrevioDetalleGuia.Nombre',
      },
      {
        label: i18n.t('AppPrevious.columns.enclosure'),
        key: 'recintoFiscal.texto',
        sortable: true,
        sortKey: 'RecintoFiscal.Nombre',
      },
      {
        label: i18n.t('AppPrevious.columns.processor'),
        key: 'usuario',
        sortable: true,
        sortKey: 'Usuario',
      },
      {
        label: i18n.t('AppPrevious.columns.customer'),
        key: 'cliente.nombre',
        sortable: true,
        sortKey: 'Cliente.Nombre',
      },
      {
        label: i18n.t('AppPrevious.columns.vendor'),
        key: 'proveedorNombre',
        sortable: true,
        sortKey: 'Proveedor.Nombre',
      },
      {
        label: i18n.t('AppPrevious.columns.dateEntry'),
        key: 'fechaEntrada',
        sortable: true,
        sortKey: 'FechaEntrada',
      },
      {
        label: i18n.t('AppPrevious.columns.datePrevious'),
        key: 'fechaPrevio',
        sortable: true,
        sortKey: 'FechaPrevio',
      },
      {
        label: i18n.t('AppPrevious.columns.masterGuide'),
        key: 'guiaMaster',
        sortable: true,
        sortKey: 'GuiaMaster',
      },
      {
        label: i18n.t('AppPrevious.columns.houseGuide'),
        key: 'guiaHouse',
        sortable: true,
        sortKey: 'GuiaHouse',
      },
      {
        label: i18n.t('AppPrevious.columns.discrepancy'),
        key: 'discrepancia',
        sortable: false,
      },
      {
        label: i18n.t('AppPrevious.columns.status'),
        key: 'status',
        sortable: true,
        sortKey: 'PrevioEstado.Nombre',
      },
      {
        label: i18n.t('AppPrevious.columns.finished'),
        key: 'fechaHoraFinalizacionPrevio',
        sortable: true,
        sortKey: 'FechaHoraFinalizacionPrevio',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: false,
      },
    ]

    if (common().userOnlyContainCustom('160')) {
      tableColumns = tableColumns.filter(f => f.key !== 'detalleGuia' && f.key !== 'fechaEntrada' && f.key !== 'discrepancia')
    }

    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'create',
        aclResource: 'Previos',
        routeName: 'apps-previous-edit',
        params: ['guid', 'conversacionId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'viewer',
        label: i18n.t('AppPrevious.list.previousViewer'),
        aclAction: 'read',
        aclResource: 'Previos',
        routeName: 'apps-previous-viewer',
        params: ['guid'],
        icon: 'ZoomInIcon',
      },
      {
        name: 'observations',
        label: i18n.t('AppPrevious.list.observations'),
        aclAction: 'read',
        aclResource: 'Previos',
        routeName: 'apps-previous-observations',
        params: ['previoId', 'conversacionId'],
        disabledCondition: () => common().containsUserCustom('160'),
        icon: 'EyeIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'Previos',
        params: ['previoId'],
        disabledCondition: item => (item.previoEstadoId === 3 || getUser().fullName !== item.usuario),
        icon: 'TrashIcon',
      },
    ])

    /* UI */
    const resolvePreviousStatusVariant = item => {
      if (item.previoEstadoId === 2) return 'warning'
      if (item.previoEstadoId === 3) return 'success'
      return 'primary'
    }

    const resolvePreviousDetailsVariant = item => {
      if (item.previoDetalleGuiaId === 1) return 'text-primary'
      if (item.previoDetalleGuiaId === 2) return 'text-success'
      if (item.previoDetalleGuiaId === 3) return 'text-danger'
      if (item.previoDetalleGuiaId === 4) return 'text-warning'

      return 'text-success'
    }
    const resolvePreviousDisagreementVariant = item => {
      if (item === true) return 'danger'
      if (item === false) return 'success'

      return 'text-primary'
    }

    return {
      // Refs & configurations
      refPreviousList,
      tableColumns,
      tableActions,
      actions,
      filters,
      stateTime,
      fetchPreviousAccumulatedExcel,
      deletePrevious,

      // UI
      resolvePreviousStatusVariant,
      resolvePreviousDetailsVariant,
      resolvePreviousDisagreementVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
